import maplibregl from "maplibre-gl";
const drawKeyEvents = (map, state, bucket_name) => {
  const isArray = Object.prototype.toString.call(state) === "[object Array]";
  var el = document.createElement("div");
  el.className = "location-pin";
  el.style.backgroundImage = `url('https://${bucket_name}.s3.eu-west-1.amazonaws.com/${state.vehicle}/${state.timestamp}/dash_center-image.jpeg'), url('https://img.icons8.com/ios-filled/344/no-camera--v1.png')`;
  el.style.width = "350px";
  el.style.height = "300px";

  if (!isArray) {
    new maplibregl.Marker({ color: "#EF6932" })
      .setLngLat([state.lng, state.lat])
      .setPopup(
        new maplibregl.Popup({ maxWidth: 350, closeButton: false }).setHTML(
          `<p>${new Date(state.timestamp * 1000)
            .toLocaleString("tr")
            .replaceAll(
              ".",
              "-"
            )}</p><img src="https://${bucket_name}.s3.eu-west-1.amazonaws.com/${
            state.vehicle
          }/${
            state.timestamp
          }/dash_center-image.jpeg" onerror="this.onerror=null; this.src='https://img.icons8.com/ios-filled/344/no-camera--v1.png'" width="350" height="300"/>`
        )
      )
      .addTo(map);
  } else {
    state.forEach(function (marker) {
      new maplibregl.Marker({ color: "#EF6932" })
        .setLngLat([marker.lng, marker.lat])
        .setPopup(
          new maplibregl.Popup({ maxWidth: 350, closeButton: false }).setHTML(
            `<p>${new Date(marker.timestamp * 1000)
              .toLocaleString("tr")
              .replaceAll(
                ".",
                "-"
              )}</p><img src="https://${bucket_name}.s3.eu-west-1.amazonaws.com/${
              marker.vehicle
            }/${
              marker.timestamp
            }/dash_center-image.jpeg" onerror="this.onerror=null; this.src='https://img.icons8.com/ios-filled/344/no-camera--v1.png'" width="350" height="300"/>`
          )
        )
        .addTo(map);
    });
  }
};

export default drawKeyEvents;
