import maplibregl from "maplibre-gl";

const drawBusStops = (map, Route) => {
  for (let i = 0; i < Route["features"].length; i++) {
    if (Route["features"][i].geometry.type === "Point") {
      const name = Route["features"][i].properties.text;
      const popup = new maplibregl.Popup({
        offset: 25,
        closeButton: false,
      }).setText(name);

      var el = document.createElement("div");
      el.id = "marker";
      el.className = "marker";
      new maplibregl.Marker(el)
        .setLngLat(Route["features"][i].geometry.coordinates)
        .setPopup(popup)
        .addTo(map);
    }
  }
};

export default drawBusStops;
