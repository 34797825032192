const drawRoute = (map, Route) => {
  map.addSource("route", {
    type: "geojson",
    data: Route,
  });
  map.addLayer({
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#26abff",
      "line-width": 2,
    },
  });
};

export default drawRoute;
