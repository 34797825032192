const drawBus = (map) => {
  const sourceName = "bus";
  map.loadImage("navigation.png", (error, image) => {
    if (error) throw error;
    map.addImage("busImage", image);
    map.addSource(sourceName, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
          },
        ],
      },
    });
    map.addLayer({
      id: "buses",
      type: "symbol",
      source: sourceName,
      layout: {
        "icon-image": "busImage",
        "icon-size": 0.6,
        "icon-rotate": ["get", "bearing"],
      },
    });
  });
  return sourceName;
};

export default drawBus;
