import { useEffect, useRef, useState } from "react";
import "./App.css";

import { Amplify, PubSub, API } from "aws-amplify";
import { getFlowrideMap } from "./queries";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { createMap } from "maplibre-gl-js-amplify";

import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";
import drawRoute from "./mapComponent/drawRoute";
import drawBusStops from "./mapComponent/drawBusStops";
import drawBus from "./mapComponent/drawBus";
import drawKeyEvents from "./mapComponent/drawKeyEvents";
import getRoute, { getKeyEvents } from "./apis.js";

const SCENE_STATE_MAP = require("./sceneState.json");
const STARTING_ZOOM_MAP = require("./zoom.json");
let STARTING_COORDINATES = [];
let BOUNDS = [];
let STARTING_ZOOM = 0;
const VEHICLE_TITLE = "Karsan Automated e-ATAK Bus –";
const OFFLINE_MESSAGE = <p>Vehicle is not active.</p>;

const getFlowrideMapTable = async (domain) => {
  return await API.graphql({
    query: getFlowrideMap,
    variables: { domainName: domain },
  });
};

const getVehicleRoute = async (root) => {
  return await getRoute(root);
};

const getVehicleKeyEvents = async (vehicleId, startDate, endDate, eventId) => {
  return await getKeyEvents(vehicleId, startDate, endDate, eventId);
};

let timeWithoutTelemetry = performance.now();
const appSyncConfig = {
  aws_appsync_graphqlEndpoint:
    "https://wvi23ehqbbd65elafnumzmtnga.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-6kh2qpi2z5cmxnqqfzbeg5f5he",
};

Amplify.configure(appSyncConfig);
Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-1:c19a9288-797a-4569-9452-e9cd0b85d7cd", // REQUIRED - Amazon Cognito Identity Pool ID
    region: "eu-west-1", // REQUIRED - Amazon Cognito Region
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "MSU-Atak-d": {
            // REQUIRED - Amazon Location Service Map resource name
            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
          },
        },
        default: "MSU-Atak-d", // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      region: "eu-west-1", // REQUIRED - Amazon Location Service Region
    },
  },
  Storage: {
    AWSS3: {
      bucket: "georoutejson", //REQUIRED -  Amazon S3 bucket name
      region: "eu-west-1", //OPTIONAL -  Amazon service region
      level: "",
    },
  },
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-west-1",
    aws_pubsub_endpoint:
      "wss://a3toujzwyviz8p-ats.iot.eu-west-1.amazonaws.com/mqtt",
  })
);

let heading = 0; // There is only 1 bus so far, so 1 heading value.

const hideStops = () => {
  const content = document.getElementById("panel-body");
  content.classList.toggle("hidden");
};

const getRemainingTime = (seconds) => {
  let suffix = "min";
  let time = seconds;
  time = time / 60;
  if (time > 60) {
    time = time / 60;
    suffix = "h";
  }
  if (!Math.ceil(time)) {
    return <span style={{ color: "#008000" }}>"Next stop"</span>;
  }
  return `~${Math.ceil(time)} ${suffix}`;
};

const StopsList = ({ destinations }) => {
  if (!destinations.length) {
    return <></>;
  }
  const listGroupItems = [];
  for (let i = 0; i < destinations.length; i++) {
    const stopName = destinations[i].name;
    const remainingTimeSec = destinations[i].remaining_time;
    listGroupItems.push(
      <li key={i} className="list-group-item">
        {stopName}{" "}
        <span style={{ float: "right" }}>
          {getRemainingTime(remainingTimeSec)}
        </span>
      </li>
    );
  }

  return <ul className="list-group">{listGroupItems}</ul>;
};

const Map = () => {
  const [destinations, setDestinations] = useState({});
  const [route, setRoute] = useState(false);
  const [state, setState] = useState(0); // PUT counter when data is not coming for - seconds..
  const [events, setEvents] = useState(false);
  const [params, setParams] = useState(false);
  const [root, setRoot] = useState("");
  const [link, setLink] = useState(false);
  const [vehicleId, setVehicleId] = useState(false);
  const mapRef = useRef(null);

  const subscribeFeed = (map, busSourceName, vehicleId) => {
    const DEVICE_ID = vehicleId;
    const MAP_TELEMETRY_TOPIC = `map/telemetry/${DEVICE_ID}`;
    const observeTelemetry = PubSub.subscribe(MAP_TELEMETRY_TOPIC);
    return observeTelemetry.subscribe({
      next: (data) => {
        timeWithoutTelemetry = performance.now();
        heading = data.value.heading;
        const destinationsMap = {};

        if ("re_status.bus_stop_state" in data.value) {
          const sceneState = data.value.re_status.bus_stop_state.state;
          const timeUntilArrival =
            data.value.re_status.current_destinations[0].time_until_arrival;
          const currentDestination =
            data.value.re_status.current_destinations[0].bus_stop.id;
          const incomingDestinations = [
            ...data.value.re_status.current_destinations,
          ];
          if (!currentDestination || !incomingDestinations.length) {
            setDestinations([]);
            return;
          }
          const currentIndex = incomingDestinations.findIndex(
            (d) => d.bus_stop.id === currentDestination
          );
          const orderedTravelTimeSecArr = [];
          data.value.re_status.current_destinations.forEach((t) =>
            orderedTravelTimeSecArr.push(t.time_until_arrival)
          );
          for (let i = 0; i < currentIndex; i++) {
            incomingDestinations.unshift(incomingDestinations.pop());
            orderedTravelTimeSecArr.unshift(orderedTravelTimeSecArr.pop());
          }
          for (let i = 0; i < incomingDestinations.length; i++) {
            let remainingTime = orderedTravelTimeSecArr[i] + timeUntilArrival;
            if (i === 0) {
              remainingTime = timeUntilArrival;
            }
            incomingDestinations[i]["remaining_time"] = remainingTime;
          }
          setState(sceneState);
          setDestinations(incomingDestinations);
          map.getSource(busSourceName).setData({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [data.value.longitude, data.value.latitude],
                  "icon-overlap": "always",
                },
                properties: {
                  bearing: heading,
                },
              },
            ],
          });
          return;
        }
        const sceneState = data.value.re_status.bus_stop_state.state;
        data.value.re_status.route_info.bus_stops.forEach((r) => {
          destinationsMap[r.id] = r;
        });
        const remaining_times = data.value.re_status.current_destinations;
        const orderedDestinations = remaining_times.map((t) => ({
          remaining_time: t.time_until_arrival,
          ...destinationsMap[t.bus_stop.id],
        }));
        setState(sceneState);
        setDestinations(orderedDestinations);
        map.getSource(busSourceName).setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [data.value.longitude, data.value.latitude],
                "icon-overlap": "always",
              },
              properties: {
                bearing: heading,
              },
            },
          ],
        });
      },
      error: (error) => console.error(error),
      complete: () => console.log("Done"),
    });
  };

  useEffect(() => {
    getFlowrideMapTable(window.location.host).then((values) => {
      setRoot(values.data.getFlowrideMap.s3);
      setVehicleId(values.data.getFlowrideMap.vehicleId);
      setLink(values.data.getFlowrideMap.link);
    });
  }, []);

  useEffect(() => {
    if (!route && root) {
      getVehicleRoute(root).then((values) => {
        setRoute(values);
      });
    }
  }, [route, root]);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const pathParams = {};
    pathParams["vehicle"] = params.get("vehicle");
    pathParams["lat"] = params.get("lat");
    pathParams["lng"] = params.get("lng");
    pathParams["timestamp"] = params.get("timestamp");
    pathParams["from"] = params.get("from");
    pathParams["to"] = params.get("to");
    pathParams["event_id"] = params.get("event_id");
    pathParams["bucket_name"] = params.get("bucket_name");
    setParams(pathParams);
  }, []);

  useEffect(() => {
    if (!events) {
      if (params && params?.vehicle !== null) {
        if (params && params?.from !== null) {
          getVehicleKeyEvents(
            params.vehicle,
            params.from,
            params.to,
            params.event_id
          ).then((values) => {
            setEvents(values);
          });
        } else {
          setEvents([
            {
              lat: params.lat,
              lng: params.lng,
              vehicle: params.vehicle,
              timestamp: params.timestamp,
            },
          ]);
          if (params.lat === "0" || params.lng === "0") {
            setEvents([]);
          }
        }
      }
    }
  }, [events, params]);

  useEffect(() => {
    if (route) {
      var route_array = [];
      if (events.length > 0) {
        events.forEach((t) =>
          route_array.push([parseFloat(t.lng), parseFloat(t.lat)])
        );
      } else {
        route_array = route["features"][0].geometry.coordinates;
      }

      var sumLong = 0;
      var sumLat = 0;
      route_array.forEach((coord) => {
        sumLong += coord[0];
        sumLat += coord[1];
      });
      STARTING_COORDINATES = [
        sumLong / route_array.length,
        sumLat / route_array.length,
      ];

      let maxValueLong = -Infinity;
      let minValueLong = Infinity;
      let maxValueLat = -Infinity;
      let minValueLat = Infinity;
      route_array.forEach((coord) => {
        if (coord[0] < minValueLong) minValueLong = coord[0];
        if (coord[0] > maxValueLong) maxValueLong = coord[0];
        if (coord[1] < minValueLat) minValueLat = coord[1];
        if (coord[1] > maxValueLat) maxValueLat = coord[1];
      });
      BOUNDS = [
        [minValueLong - 5, minValueLat - 5], // Southwest coordinates
        [maxValueLong + 5, maxValueLat + 5], // Northeast coordinates
      ];

      let startingLat = (minValueLat * Math.PI) / 180;
      let startingLong = (minValueLong * Math.PI) / 180;
      let destinationLat = (maxValueLat * Math.PI) / 180;
      let destinationLong = (maxValueLong * Math.PI) / 180;
      let radius = 6571;

      // Haversine equation
      let distanceInKilometers =
        Math.acos(
          Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) *
              Math.cos(destinationLat) *
              Math.cos(startingLong - destinationLong)
        ) * radius;

      for (let i = 0; i < Object.keys(STARTING_ZOOM_MAP).length; i++) {
        if (distanceInKilometers < Object.keys(STARTING_ZOOM_MAP)[i]) {
          STARTING_ZOOM = STARTING_ZOOM_MAP[Object.keys(STARTING_ZOOM_MAP)[i]];
          break;
        } else {
          continue;
        }
      }

      const idleTelemetryInterval = setInterval(function () {
        const passedTimeSinceLastTelemetry =
          performance.now() - timeWithoutTelemetry;
        const seconds = Math.floor(passedTimeSinceLastTelemetry / 1000);
        if (seconds >= 10) {
          setState(0);
        }
      }, 1000);
      let connectionMonitorInterval;
      async function initializeMap() {
        // We only want to initialize the underlying maplibre map after the div has been rendered
        if (mapRef.current != null) {
          const map = await createMap({
            container: mapRef.current, // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
            center: STARTING_COORDINATES, // [Longitude, Latitude]
            zoom: STARTING_ZOOM,
            maxBounds: BOUNDS,
          });
          map.dragRotate.disable();

          if (params && params?.vehicle !== null) {
            if (events.length > 0) {
              map.on("load", function () {
                drawKeyEvents(map, events, params.bucket_name);
                connectionMonitorInterval = setInterval(function () {}, 200);
              });
            }
          } else {
            map.on("load", function () {
              drawRoute(map, route);
              drawBusStops(map, route);
              const busSourceName = drawBus(map);
              let observeable = subscribeFeed(map, busSourceName, vehicleId);
              connectionMonitorInterval = setInterval(function () {
                if (observeable.closed) {
                  observeable.unsubscribe();
                  observeable = subscribeFeed(map, busSourceName, vehicleId);
                }
              }, 200);
            });
          }
        }
      }
      initializeMap();
      if (!params || params?.vehicle === null) {
        return function cleanup() {
          clearInterval(idleTelemetryInterval);
          clearInterval(connectionMonitorInterval);
          document.location.reload(); // Fix for multiple map instances on development
        };
      } else {
        return function cleanup() {
          clearInterval(idleTelemetryInterval);
          clearInterval(connectionMonitorInterval);
        };
      }
    }
  }, [route, events, params, vehicleId]);
  const title = window.location.host.includes("adastec") ? "" : root;

  (function () {
    var newTitle = "ADASTEC " + title;
    var titleTag = document.querySelector("title");
    titleTag.textContent = newTitle;
  })();

  return (
    <>
      <div ref={mapRef} id="map" className="fullheight-map" />
      {params && params?.vehicle === null ? (
        <div className="panel panel-default">
          <div className="panel-heading" onClick={hideStops}>
            {VEHICLE_TITLE} {root}
            <span
              style={{
                float: "right",
              }}
            >
              <div className="whiteline"></div>
              <div className="whiteline"></div>
              <div className="whiteline"></div>
            </span>
          </div>
          <div className="panel-body" id="panel-body">
            {state === 0 ? (
              <>
                {OFFLINE_MESSAGE}
                {link && (
                  <p>
                    Please check <a href={link}>{link.substring(8)}</a> for the
                    route schedule.
                  </p>
                )}
              </>
            ) : (
              <>
                <p>State: {SCENE_STATE_MAP[state]}</p>
                <p> </p>
                <StopsList destinations={destinations} />
              </>
            )}
          </div>
        </div>
      ) : !events ? (
        <div className="panel panel-loading">
          <div className="loader"></div>
        </div>
      ) : (
        events.length === 0 && (
          <div className="panel panel-default">
            <div className="panel-heading">
              Required location information is missing!
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Map;
