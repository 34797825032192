/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFlowrideMap = /* GraphQL */ `
  query GetFlowrideMap($domainName: String!) {
    getFlowrideMap(domainName: $domainName) {
      domainName
      s3
      vehicleId
      link
    }
  }
`;
